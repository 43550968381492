<template>
  <div>
    <div class="content-container flex flex-col lg:gap-4 p-0 lg:!p-4 lg:!pt-0">
      <BrComponent component="top-row/container" />
    </div>

    <div class="md:content-container flex flex-col lg:gap-4 md:pt-4">
      <div class="flex flex-col lg:flex-row md:gap-8">
        <BrComponent
          class="w-full lg:w-3/12"
          component="left-column/container"
        />
        <BrComponent
          class="w-full lg:w-9/12 overflow-hidden"
          component="right-column/container"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BrComponent } from "@bloomreach/vue-sdk";
export default {
  name: "TopLevelCategory",
  components: { BrComponent },
};
</script>

<style lang="postcss" scoped></style>
