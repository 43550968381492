<template>
  <div
    v-if="data"
    class="w-full relative"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div
      class="w-full h-full flex flex-col justify-center text-left content-container !px-6 !py-6 font-gilroy"
      :class="innerWrapperClasses.classes"
      :style="innerWrapperClasses.styles"
    >
      <TextAndMedia
        :title="title"
        :body="body"
        :images="images"
        :youtube-url="youtubeURL"
        :iframe-url="iframeUrl"
        :audio-src="audioSrc"
        :style-type="styleType"
        :cta="cta"
        :center-text="centerText"
        :content-colour="contentColour"
        :body-colour="bodyTextColour"
        :cta-btn-colour="ctaBtnColour"
        :read-more="readMore"
        :heading-type="headingType"
        :cta-text-colour="ctaTextColour"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import { getDeviceImages } from "~/utils/bloomreach/images";
import TextAndMedia from "~/components/text-and-media/TextAndMedia.vue";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component data fields from bloomreach SDK
const data = computed(
  () => getContainerItemContent(props.component, props.page) ?? null
);

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Deconstruct computed style properties from composable
const {
  wrapperClasses,
  innerWrapperClasses,
  bodyTextColour,
  contentColour,
  ctaBtnColour,
  ctaTextColour,
} = globalComponentProperties(properties, props.page);

// Data / Properties
const title = computed(() => data.value?.title ?? "");
const body = computed(() => data.value?.body.value ?? "");
const images = computed(() => getDeviceImages(data.value?.image ?? {}));
const youtubeURL = computed(() => data.value.youtubeSource || "");
const iframeUrl = computed(() => data.value.iframeUrl);
const audioSrc = computed(() => data.value.audioSrc);
const cta = computed(() => (data.value?.cta?.href ? data.value?.cta : {}));
const styleType = computed(() => properties.value.styleType);
const centerText = computed(() => properties.value.centerText);
const headingType = computed(() => properties.value.headingType);
const readMore = computed(() => properties.value.readMore);
</script>
