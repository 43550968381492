
import Vue, { PropType } from "vue";
import ResponsiveImage from "~/components/shared/responsive-image/ResponsiveImage.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import BodyText from "~/components/UI/text/BodyText.vue";
import { Images } from "~/utils/bloomreach/images";
import { toYoutubeUri } from "~/lib/product";

export default Vue.extend({
  name: "TextAndMedia",
  components: { ResponsiveImage, DefaultButton, BodyText },
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    styleType: {
      type: String as PropType<string>,
      required: true,
      default: "version 1",
    },
    body: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    images: {
      type: Object as PropType<Images>,
      required: false,
      default: (): Images => ({
        desktopSrc: "",
        tabletSrc: "",
        mobileSrc: "",
      }),
    },
    youtubeUrl: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    iframeUrl: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    audioSrc: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    cta: {
      type: Object as PropType<object>,
      required: false,
      default: () => ({}),
    },
    centerText: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    contentColour: {
      type: String as PropType<String>,
      required: false,
      default: "#1a202c",
    },
    bodyColour: {
      type: String as PropType<String>,
      required: false,
      default: "#252525",
    },
    ctaBtnColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    ctaTextColour: {
      type: String as PropType<String>,
      required: false,
      default: "",
    },
    readMore: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    headingType: {
      type: String as PropType<String>,
      required: false,
      default: "H3",
    },
  },
  computed: {
    youtubeId(): string {
      return this.youtubeUrl
        ? this.youtubeUrl?.split("v=")[1]?.split("&")[0]
        : "";
    },
    flexDirectionClass(): string {
      switch (this.styleType) {
        case "version 1":
          return "flex-col";
        case "version 2":
          return "flex-col md:flex-row";
        case "version 3":
          return "flex-col md:flex-row-reverse";
        default:
          return "flex-col";
      }
    },
    widthClasses(): string {
      return this.styleType === "version 1" ? "w-full" : "w-full md:w-1/2";
    },
    textAlignmentClasses(): string {
      return this.styleType === "version 1" && this.centerText
        ? "md:items-center md:text-center"
        : this.centerText
          ? "justify-center"
          : "";
    },
    iframeClasses(): string {
      return this.youtubeId && !this.iframeUrl
        ? "aspect-video min-h-[200px] md:min-h-[270px]"
        : "min-h-[800px]";
    },
    iframeSrc(): string {
      return this.youtubeId && !this.iframeUrl
        ? toYoutubeUri(this.youtubeId)
        : this.iframeUrl;
    },
    audio(): string {
      return this.audioSrc;
    },
    hasImages(): Boolean {
      return !!this.images.desktopSrc;
    },
  },
});
